<!--公众号，五标五工区，车辆出入统计页面-->

<template>
	<div>
		<!--选择日期-->
		<div>
			<div style="margin-top: 6%">
				<el-radio-group v-model="radio1" class="ml-4" @change="changeRadio">
					<el-radio label="1" size="large">单日</el-radio>
					<el-radio label="2" size="large">统计</el-radio>
				</el-radio-group>
			</div>

			<div style="margin-top: 5%" v-if="radio1 === '1'">
				<el-date-picker
					v-model="date"
					type="date"
					placeholder="请选择日期"
					format="YYYY-MM-DD"
					value-format="YYYYMMDD"
					@change="getCarMessage"
				/>
			</div>

			<div style="margin-top: 5%" v-if="radio1 === '2'">
				<el-date-picker
					v-model="month"
					type="month"
					placeholder="请选择月份"
					value-format="YYYY-MM"
					@change="getMonth"
				/>
			</div>
		</div>

	<!--	详细表格-->
		<div style="margin-top: 5%">
			<el-table :data="tableData" style="width: 100%">
				<el-table-column prop="carId" label="车牌" width="90" />
				<el-table-column prop="rNum" label="只入" width="90" />
				<el-table-column prop="cNum" label="只出" width="90" />
				<el-table-column prop="closeNum" label="闭合" width="90" />
			</el-table>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowDate, setNowDateNo, setNowMonth2} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			date: '',
			tableData: [],
			radio1: '1',
			month: ''
		}
	},
	created() {
		// 获取昨天日期
		let today = new Date();
		this.date = setNowDateNo(new Date(today.getTime() - 24 * 60 * 60 * 1000))
		this.month = setNowMonth2(new Date())
		this.getCarMessage()
	},
	methods: {
		getCarMessage(){
			this.axios.get('/carNumContrast/analysisCarNum', (response) => {
				this.tableData = response.obj
			},{
				time: this.date
			})
		},
		getMonth(){
			let arr = this.month.split('-')
			const thisDate = new Date(arr[0], arr[1], 0)  //当天数为0 js自动处理为上一月的最后一天
			const begin_time = arr[0] + arr[1] + '01'
			const end_time = arr[0] + arr[1] + thisDate.getDate()
			this.axios.get('/carNumContrast/analysisCarNumTimes', (response) => {
				this.tableData = response.obj
			},{
				begin_time: begin_time,
				end_time: end_time
			})
		},
		changeRadio(){
			if (this.radio1 === '1'){
				this.getCarMessage()
			}
			if (this.radio1 === '2'){
				this.getMonth()
			}
		}
	}
}
</script>

<style>

</style>